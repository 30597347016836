<template>
	<div>
		<v-row>
			<v-col>
				<h2>ご協力ありがとうございました</h2>
				<p class="mt-5 mb-5 text-subtitle-1" v-if="isYahoo">以下のコードをYahoo!クラウドソーシングの画面に入力してください。</p>
				<p class="mt-5 mb-5 text-subtitle-1" v-else>本調査は、関連研究はEBLISEプロジェクトの一部として行われています。<br/>関連研究については、<a href="https://eblise.org/" target="_blank" class="text-decoration-none">プロジェクトウェブサイト</a>をご覧ください。</p>
			</v-col>
		</v-row>
		<v-row justify="center" v-if="isYahoo">
			<v-col cols="auto">
				<v-alert
					border="left"
					color="blue"
					width="300"
					text
				><span class="text-h3">V8QWL3JM</span></v-alert>
			</v-col>
		</v-row>
		<v-row justify="center" v-if="!isYahoo && userId">
			<v-col cols="auto">
				<v-btn
					large
					depressed
					class="mt-16"
					width="330"
					@click="logout"
				>
						ログアウト
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SurveyResult',
	data () {
		return {
			isYahoo: false
		}
	},
	computed: {
		...mapGetters({
			userId: 'stateUserId'
		})
	},
	methods: {
		...mapActions(['userLogout']),
		logout() {
			this.userLogout().then(() => {
				this.$router.push('/')
			})
		}
	}
}
</script>